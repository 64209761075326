<header class="top-bar flex-row" aria-label="header" i18n-aria-label>
  <mat-icon class="arrow-back cursor-pointer" (click)="navigateToEventsPage()">arrow_back</mat-icon>
  <span class="page-label justify-center flex-3" i18n>Invite Players</span>
</header>

<div class="invite-form">
  <div class="search-box">
    <mat-form-field appearance="outline">
      <mat-label class="mat-label" i18n>Search...</mat-label>
      <span matPrefix><mat-icon class="va-middle search-icon" svgIcon="search"></mat-icon></span>
      <input type="text" matInput [formControl]="searchUser" [matAutocomplete]="auto" />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        @for (friend of filteredOptions | async; track friend.id) {
          <mat-option [value]="friend"> {{ friend?.firstName }} {{ friend?.lastName }} </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    <div class="players-label">
      <span i18n>Friends</span>
    </div>

    <div class="flex-row align-center" (click)="copyLink()">
      <mat-icon class="link-icon" hbButtonStateAnimation [originalColor]="'var(--color-blue-latest)'" [clickedColor]="'var(--color-gold)'">link</mat-icon>
      <span i18n class="invite-text">Invite to group via Link</span>
    </div>

    <div class="friend-list">
      @for (friend of filteredFriends; track friend.id) {
        <div class="friend-item cursor-pointer">
          <div class="upper-border"></div>
          <div class="flex-row space-between align-center">
            <div mat-card-avatar class="user-avatar-rectangle">
              @if (friend?.photoUrl) {
                <img class="image" loading="lazy" [src]="friend?.photoUrl" alt="friend photo" i18n-alt />
              } @else {
                <div class="initials">
                  {{ friend?.initial }}
                </div>
              }
            </div>
            <div class="user-info flex-col flex-auto">
              <p class="username">&#64;{{ friend?.firstName }}{{ friend?.lastName }}</p>
              <div class="flex-row align-center">
                <p class="fullname info-text">{{ friend?.firstName }} {{ friend?.lastName }}</p>
                <div class="user-position">
                  @for (position of friend?.positions; track position.id) {
                    <div [ngClass]="['position-' + position?.color, 'position-small', 'position-space']">
                      <span class="position-text">{{ position?.title }}</span>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div
              class="example-section"
              i18n-matTooltip
              [matTooltip]="friend.status || ''"
              [matTooltipPosition]="'below'"
              #tooltip="matTooltip"
            >
              <mat-checkbox
                class="example-margin"
                color="primary"
                [(ngModel)]="friend.checked"
                [disabled]="friend.disabled"
                (change)="toggleCheckbox($event, friend)"
              ></mat-checkbox>
            </div>
          </div>
        </div>
      }
    </div>

    <div class="flex-col space-between align-center gap-10">
      <button class="apply fill-button" mat-raised-button (click)="inviteFriends()">
        <span class="va-middle" i18n>Apply</span>
      </button>
      <button class="cancel outline-button" mat-raised-button (click)="navigateToEventsPage()">
        <span class="va-middle" i18n>Cancel</span>
      </button>
    </div>
  </div>
</div>
