import { Injectable } from '@angular/core';
import { Apollo, MutationResult } from 'apollo-angular';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetMyFriendsGQL, GetMyFriendsQuery, InviteUninvitePlayersGQL } from 'src/app/services/hobbyts.service';
import { errorHandler, queryResponseHandler } from 'src/app/utils/handlers.utils';

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  constructor(
    private apollo: Apollo,
    private getMyFriendsGQL: GetMyFriendsGQL,
    private inviteUninvitePlayersGQL: InviteUninvitePlayersGQL
  ) {}

  public getFriendsEnrolledInEvent(eventId: string): Observable<GetMyFriendsQuery | null> {
    return this.getMyFriendsGQL.watch({ eventId }, { fetchPolicy: 'no-cache' }).valueChanges.pipe(
      map((res) => queryResponseHandler<GetMyFriendsQuery>(res)),
      catchError((err) => errorHandler(err))
    );
  }

  public applyInvitation(eventId: string, body: any): Observable<MutationResult<unknown>> {
    return this.apollo.mutate({
      mutation: this.inviteUninvitePlayersGQL.document,
      variables: {
        inviteUninvitePlayersInput: {
          eventId,
          users: body
        }
      }
    });
  }
}
