import { Component, DestroyRef, HostBinding, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AsyncPipe, Location, NgClass } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

import { generateEventLink, generateInitials, generatePositions } from 'src/app/utils/profile.utils';
import { User } from '../../services/hobbyts.service';
import { InviteService } from './invite.service';
import { getSnackBarConfig } from 'src/app/utils/material.utils';
import { ButtonStateAnimationDirective } from 'src/app/shared/button-animation.directive';
import { StorageService } from 'src/app/services/storage.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hb-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatChipsModule,
    MatCardModule,
    NgClass,
    MatCheckboxModule,
    MatButtonModule,
    AsyncPipe,
    MatTooltipModule,
    ButtonStateAnimationDirective
  ],
})
export class InviteComponent implements OnInit {
  @HostBinding('class.background-host-page') public backgroundHostPageClass = true;
  public friends!: any[];
  public filteredFriends!: any[];
  public filteredOptions!: Observable<any[]>;
  public searchUser = new FormControl();
  private enrolledFriends!: any[];
  private eventId = '';

  constructor(
    private inviteService: InviteService,
    private location: Location,
    private activeRoute: ActivatedRoute,
    protected sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard,
    private storageService: StorageService,
    private destroyRef: DestroyRef
  ) {}

  public ngOnInit(): void {
    this.eventId = this.activeRoute.snapshot.paramMap.get('id') || '';

    this.inviteService.getFriendsEnrolledInEvent(this.eventId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: any) => {
      this.friends = data?.getMyFriends.friends;
      this.enrolledFriends = data.getMyFriends.enrolledUsers;
      this.filteredFriends = [...this.friends];

      if (this.friends) {
        this.getPlayerList();

        this.filteredOptions = this.searchUser.valueChanges.pipe(
          startWith(''),
          map((value) => (typeof value === 'string' ? value : `${value.firstName} ${value.lastName}`)),
          map((name) => this._filter(name))
        );
      }
    });
  }

  public navigateToEventsPage(): void {
    this.location.back();
  }

  public toggleCheckbox(event: any, friend: User): void {
    const foundIndex = this.enrolledFriends.findIndex((f) => f.playerId === friend.id);
    if (foundIndex > -1 && !event.checked) {
      this.enrolledFriends.splice(foundIndex, 1);
    }
    if (event.checked) {
      friend.playerId = friend.id;
      this.enrolledFriends.push(friend);
    }
  }

  public displayFn(user: User): string {
    return user?.firstName ? `${user.firstName} ${user.lastName}` : '';
  }

  public inviteFriends(): void {
    const body = this.friends.map((f) => ({ userId: f.id, isInvite: f.checked }));
    this.inviteService.applyInvitation(this.eventId, body).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    this.location.back();
  }

  public copyLink(): void {
    this.clipboard.copy(generateEventLink(this.storageService, this.eventId));
    this.snackBar.open($localize`Link copied`, $localize`:@@close:Close`, getSnackBarConfig());
  }

  private getPlayerList(): void {
    this.friends.forEach((player: any) => {
      player.positions = [...generatePositions(player)];
      player.initial = generateInitials(player?.firstName, player?.lastName);

      const enrolledFriend = this.enrolledFriends.find((friend) => friend.playerId === player.id);

      if (enrolledFriend) {
        player.status = enrolledFriend.status;
        player.checked = !!enrolledFriend;
        player.disabled = enrolledFriend && enrolledFriend.status === 'accepted';
      }

      if (player?.photoUrl) {
        player.photoUrl = this.sanitizer.bypassSecurityTrustUrl(player?.photoUrl);
      }
    });
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();
    if (filterValue) {
      this.filteredFriends = this.friends.filter(
        (option: any) =>
          option.firstName.toLowerCase().includes(filterValue) || option.lastName.toLowerCase().includes(filterValue)
      );
    } else {
      this.filteredFriends = [...this.friends];
    }

    return this.filteredFriends;
  }
}
